<template>
    <div id="BaseConfig">
        <Form ref="BaseConfigForm" :model="formInline" :disabled="this.enterEdit==false"   label-position="right" :rules="ruleInline">
            <Card style="margin:10px 10px 10px 10px;">
                <p slot="title" align="left"><b>基本数据配置</b></p>
                <Row :gutter="60" type="flex" justify="center">
                  <i-col span="6" >
                    <FormItem prop="companycode" label="文件编号中的公司编码:" >
                        <i-input type="text"  v-model="formInline.companycode" :maxlength=20 show-word-limit  :autofocus="true"/>
                    </FormItem>
                  </i-col>     
                  <i-col span="6">               
                    <FormItem prop="companyname" label="文件中的公司名称:" >
                        <i-input type="text"  v-model="formInline.companyname" :maxlength=100 show-word-limit  />
                    </FormItem>
                  </i-col>                    
                  <i-col span="6">
                    <FormItem prop="version" label="文件版本号:">
                        <i-input type="text" v-model="formInline.version" :maxlength=20 show-word-limit/>
                    </FormItem>
                  </i-col>                  
                  <i-col span="6">               
                    <FormItem prop="effectdate" label="  文件生效日期:" >
                        <i-input type="text" v-model="formInline.effectdate" :maxlength=20 show-word-limit/>
                    </FormItem>
                  </i-col>   
                </Row>
                <Row :gutter="60" type="flex" justify="center">
                    <i-col span="6" >
                        <FormItem prop="codeinvin" label="整车编码中的企业代码:" >
                            <i-input type="text" v-model="formInline.codeinvin" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>     
                    <i-col span="6">
                    </i-col>                  
                    <i-col span="6">               
                    </i-col>  
                    <i-col span="6">               
                    </i-col>   
                </Row>                
            </Card>
            <Card style="margin:10px 10px 10px 10px;">
                <p slot="title" align="left"><b>人员信息配置</b></p>
                <Row :gutter="60" type="flex" justify="center">
                    <i-col span="6" >
                        <FormItem prop="ceo" label="总经理:" >
                            <i-input type="text" v-model="formInline.ceo" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                        <FormItem prop="productionmanager" label="生产部长:" >
                            <i-input type="text" v-model="formInline.productionmanager" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                    <FormItem prop="qualitymanager" label="质量/认证负责人及联络员:" >
                        <i-input type="text" v-model="formInline.qualitymanager" :maxlength=20 show-word-limit  />
                    </FormItem>
                    </i-col>     
                    <i-col span="6">
                        <FormItem prop="officemanager" label="办公室主任:" >
                            <i-input type="text" v-model="formInline.officemanager" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>                  
                </Row>
                <Row :gutter="60" type="flex" justify="center">
                    <i-col span="6" >
                        <FormItem prop="ccmanager" label="仓储部长:" >
                            <i-input type="text" v-model="formInline.ccmanager" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                        <FormItem prop="techmanager" label="技术部长:" >
                            <i-input type="text" v-model="formInline.techmanager" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                    <FormItem prop="cgmanager" label="采购部长:" >
                        <i-input type="text" v-model="formInline.cgmanager" :maxlength=20 show-word-limit  />
                    </FormItem>
                    </i-col>     
                    <i-col span="6">
                        <FormItem prop="salemanager" label="销售部长:">
                            <i-input type="text" v-model="formInline.salemanager" :maxlength=20 show-word-limit/>
                        </FormItem>
                    </i-col>                  
                </Row>
                <Row :gutter="60" type="flex" justify="center">
                    <i-col span="6" >
                        <FormItem prop="purchaser" label="零部件采购人:" >
                            <i-input type="text" v-model="formInline.purchaser" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                        <FormItem prop="partchecker" label="零部件入库检验人:" >
                            <i-input type="text" v-model="formInline.partchecker" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                        <FormItem prop="partintosigner" label="仓库零部件入库签收人:" >
                            <i-input type="text" v-model="formInline.partintosigner" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>     
                    <i-col span="6">
                        <FormItem prop="partoutsigner" label="生产部零部件出库签收人:" >
                            <i-input type="text" v-model="formInline.partoutsigner" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>                  
                </Row>       
                <Row :gutter="60" type="flex" justify="center">
                    <i-col span="6" >
                        <FormItem prop="gcjyuser" label="过程检验员:" >
                            <i-input type="text" v-model="formInline.gcjyuser" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                        <FormItem prop="zpxjuser" label="装配过程巡检:" >
                            <i-input type="text" v-model="formInline.zpxjuser" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                    <FormItem prop="cpjyuser" label="成品检验员:" >
                        <i-input type="text" v-model="formInline.cpjyuser" :maxlength=20 show-word-limit  />
                    </FormItem>
                    </i-col>     
                    <i-col span="6">
                        <FormItem prop="ccjyuser" label="出厂检验员:" >
                            <i-input type="text" v-model="formInline.ccjyuser" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>                  
                </Row>             
                <Row :gutter="60" type="flex" justify="center">
                    <i-col span="6" >
                        <FormItem prop="maintainername" label="设备保养人名字:">
                            <i-input type="text" v-model="formInline.maintainername" :maxlength=20 show-word-limit/>
                        </FormItem>
                    </i-col>
                    <i-col span="6" >

                    </i-col>
                    <i-col span="6" >

                    </i-col>     
                    <i-col span="6">
                    </i-col>                  
                </Row>  
                <Row :gutter="60" type="flex" justify="center">
                    <i-col span="6" >
                        <FormItem prop="filewriter" label="文档编写人:" >
                            <i-input type="text" v-model="formInline.filewriter" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                        <FormItem prop="filechecker" label="文档审核人:" >
                            <i-input type="text" v-model="formInline.filechecker" :maxlength=20 show-word-limit  />
                        </FormItem>
                    </i-col>
                    <i-col span="6" >
                    <FormItem prop="fileapprover" label="文档批准人:" >
                        <i-input type="text" v-model="formInline.fileapprover" :maxlength=20 show-word-limit  />
                    </FormItem>
                    </i-col>     
                    <i-col span="6">

                    </i-col>                  
                </Row>     
                            
            </Card>
        </Form>
        <div style="margin: 0 auto;">
            <Button type="primary" size="large" @click="onbtnclick">{{btntextcompute}}</Button>
            <Button type="error" ghost size="large" @click="enterEdit=false" v-show="enterEdit" style="margin-left: 20px;">取消</Button>
        </div>
    </div>
</template>

<script>
export default {
    name:'BaseConfig',
    data () {
        return {
            ruleInline: {
                companycode: [
                    { required: true, message: '请输入公司代码', trigger: 'blur' }
                ],
                companyname: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' }
                ],
                version: [
                    { required: true, message: '请输入文件版本号', trigger: 'blur' }
                ],
                codeinvin: [
                    { required: true, message: '请输入整车编码中的企业代码', trigger: 'blur' }
                ],
                ceo: [
                    { required: true, message: '请输入总经理名称', trigger: 'blur' }
                ],
                ccmanager: [
                    { required: true, message: '请输入仓储部长名称', trigger: 'blur' }
                ],
                techmanager: [
                    { required: true, message: '请输入技术部长名称', trigger: 'blur' }
                ],
                zpxjuser: [
                    { required: true, message: '请输入装配过程巡检名称', trigger: 'blur' }
                ],
                cpjyuser: [
                    { required: true, message: '请输入成品检验员名称', trigger: 'blur' }
                ],
                cgmanager: [
                    { required: true, message: '请输入采购部长名称', trigger: 'blur' }
                ],
                officemanager: [
                    { required: true, message: '请输入办公室主任名称', trigger: 'blur' }
                ],
                salemanager: [
                    { required: true, message: '请输入销售部长名称', trigger: 'blur' }
                ],
                productionmanager: [
                    { required: true, message: '请输入生产部长名称', trigger: 'blur' }
                ],
                qualitymanager: [
                    { required: true, message: '请输入质量/认证负责人名称', trigger: 'blur' }
                ],
                maintainer: [
                    { required: true, message: '请输入设备保养人名称', trigger: 'blur' }
                ],
                filewriter: [
                    { required: true, message: '请输入文档编写人名称', trigger: 'blur' }
                ],
                filechecker: [
                    { required: true, message: '请输入文档审核人名称', trigger: 'blur' }
                ],
                fileapprover: [
                    { required: true, message: '请输入文档批准人名称', trigger: 'blur' }
                ],
                purchaser: [
                    { required: true, message: '请输入零部件采购人人名称', trigger: 'blur' }
                ],
                partchecker: [
                    { required: true, message: '请输入入库检验人名称', trigger: 'blur' }
                ],
                partintosigner: [
                    { required: true, message: '请输入入库签收人名称', trigger: 'blur' }
                ],
                partoutsigner: [
                    { required: true, message: '请输入出库签收人名称', trigger: 'blur' }
                ],
                ccjyuser: [
                    { required: true, message: '请输入出厂检验员名称', trigger: 'blur' }
                ],
                gcjyuser: [
                    { required: true, message: '请输入过程检验员名称', trigger: 'blur' }
                ],
                effectdate: [
                    { pattern: /^["\-\.\/\d"]{10}$/, message: '请填写正确的日期', trigger: 'blur' }
                ],
            },
            formInline :{
                companyid: this._self.$root.$store.state.LogonInfo.companyid,
                modifyuser:this._self.$root.$store.state.LogonInfo.userid,
                companycode:null,
                companyname:null,
                version:null,
                codeinvin:null,
                effectdate:null,
                ceo:null,
                productionmanager:null,
                qualitymanager:null,
                maintainername:null,
                filewriter:null,
                filechecker:null,
                fileapprover:null,
                partchecker:null,
                purchaser:null,
                partintosigner:null,
                partoutsigner:null,
                ccjyuser:null,
                gcjyuser:null,
                ccmanager:null,
                techmanager:null,
                zpxjuser:null,
                cpjyuser:null,
                cgmanager:null,
                officemanager:null,
                salemanager:null
            },
            cfgdata:null,
            enterEdit:false,
            inputstyle:{
                disabled: this.enterEdit==false
            },
        }
    },
    watch: {
        $route: {
            handler: function(val, oldVal){
                console.log(val)
                if(val !=null){
                    this.formInline = val
                }
            },
        }
    },
    computed:{
        btntextcompute() {
            if(this.enterEdit){
                return '保存'
            }
            else{
                return '编辑'
            }
            return ;
        }
    },
    mounted(){
        this.readcfg()
    },
    methods:{
        onbtnclick(){
            if(this.enterEdit){
                this.savecfg()
            }
            else{
                this.enterEdit = true
            }
        },
        readcfg (){
            this.$axios({
                method:'post',
                url:"/api/checkdept/cfg/get",
                data: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid
                },
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                console.log(res)
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.cfgdata = res.data.data
                    this.$store.commit('changeModelCfg',this.cfgdata)
                    if(this.cfgdata !=null){
                        this.formInline = this.cfgdata
                    }
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));             
        },
        savecfg (){
            console.log(this.formInline)
            if(this.formInline.effectdate == null || this.formInline.effectdate == ''){
                this.$Message.error("所有参数都是必填项!")
                return
            }
            this.$refs['BaseConfigForm'].validate((valid) =>{
                if(valid){
                    let posturl=null
                    if(this.cfgdata == null){
                        posturl = "/api/checkdept/cfg/addnew"
                    }
                    else{
                        posturl = "/api/checkdept/cfg/update"
                    }

                    for(var p in this.formInline){
                        if(this.formInline[p] == null){
                            delete this.formInline[p]
                        }
                    }
                    this.$axios({
                        method:'post',
                        url:posturl,
                        data: this.formInline,
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: function(obj) {
                            var str = [];
                            for(var p in obj){
                                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                            }
                            return str.join("&");
                        }
                    }).then(function(res){
                        console.log(res)
                        if(res.data.resultCode ==0){
                            console.log(res)
                            this.$Message.success({content:res.data.msg, duration:3})
                            this.enterEdit = false
                            this.cfgdata = this.formInline
                            this.$store.commit('changeModelCfg',this.cfgdata)

                            eventBus.$emit('modelcfgupdate',this.cfgdata)
                        }
                        else{
                            this.$Message.error({content:res.data.data.msg, duration:3})
                        }
                    }.bind(this));
                }
                else{
                    this.$Message.error("请输入正确的参数")
                }
            })
        }
    }
}
</script>

<style scoped>
.ivu-form .ivu-form-item-label {
    font-size: 10px;
}
</style>